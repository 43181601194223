@import '../../../../common/styles/font-size';

.device-farm-end {
  margin-bottom: 210px;
  .w-1440 {
    h2 {
      @include text-42-fw-700;
    }
    .end-image {
      filter: drop-shadow(8px 4px 20px rgba(16, 24, 40, 0.2));
      border-radius: 12px;
      margin: auto;
    }
    .end-description {
      background: #101828;
      color: #FFFFFF;
      height: fit-content;
      padding: 32px 20px;
      margin: auto;
      & > h3 {
        @include text-20-fw-700;
      }
      & > p {
        @include text-16-fw-400;
      }
    }
  }

}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .device-farm-end {
    margin-top: 0px;
    margin-bottom: 180px;
    .w-1440 {
      margin: auto 16px;
      h2 {
        @include text-24-fw-700;
      }
      .end-image {
        margin-bottom: 48px;
      }
    }
  }
}