@import '../../../../common/styles/font-size';

.device-farm-cloud-devices {
  margin-top: 200px;
  margin-bottom: 160px;
  .w-1440 {
    .box-title {
      margin-bottom: 40px;
      .title-cloud-devices {
        @include text-42-fw-700;
        text-align: center;
        margin-bottom: 16px;
      }
      .sub-title {
        font-family: Manrope;
        font-weight: 400;
        font-size: 16px;
        line-height: calc(24/16);
        text-align: center;
      }
    }
    .end-image {
      filter: drop-shadow(8px 4px 20px rgba(16, 24, 40, 0.2));
      border-radius: 12px;
      margin: auto;
    }
    .end-description {
      background: #101828;
      color: #FFFFFF;
      height: fit-content;
      padding: 32px 20px;
      margin: auto;
      & > h3 {
        @include text-20-fw-700;
      }
      & > p {
        @include text-16-fw-400;
      }
    }
  }

}

@media screen and (max-width: 991px) {
  .device-farm-cloud-devices {
    margin-top: 0px;
    margin-bottom: 80px;
    .w-1440 {
      margin: auto 16px;
      h2 {
        @include text-24-fw-700;
      }
      .end-image {
        margin-bottom: 48px;
      }
    }
  }
}